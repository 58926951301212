import { GridItemType } from '.';

export enum PaymentType {
  manualCard = 0,
  applePay = 1,
  googlePay = 2
}

export type SaleItem = {
  localStorageBasketItemIdentifier?: number;
  referenceId: number;
  type: GridItemType;
  quantity: number;
  orderDetail?: string;
  bundleSelections?: BundleSelection[];
  modifierSelections?: ModifierSelection[];
};

type BundleSelection = {
  referenceId: number;
  bundleGroupId: number;
};

type ModifierSelection = {
  modifierId: number;
  quantity: number;
};

export type Sale = {
  paymentMethod: PaymentType;
  items: Array<SaleItem>;
};

export type PaymentBreakdown = {
  totalAmount: number;
  totalTaxAmount: number;
  subtotal: number;
  availableBasketItems?: SaleItem[];
  unavailableBasketItems?: SaleItem[];
};

export enum PaymentStatus {
  Success = 'Success',
  Failure = 'Failure',
  Pending = 'Pending'
}

export type SaleResponse = {
  orderId?: number;
  averageWaitTimeSeconds?: number;
};

export type ValitorPaymentResponseInfo = {
  paymentMethod?: number;
  authorizationNumber: string;
  transactionNumber: string;
  cardType: string;
  agreementNumber: string;
};

export type StripePaymentResponseInfo = {
  paymentMethod?: number;
  authorizationNumber: string;
  transactionNumber: string;
  cardType: string;
  agreementNumber: string;
};

export type DigitalWalletResponseInfo = {
  amount: number;
  token: string;
  paymentMethod: PaymentType;
};
